import { Typography } from "@mui/material";

function Home() {
    return (
        <>
            <Typography variant="h3" align="center">Welcome to the SLP website!</Typography>
        </>
    )
}

export default Home