import ThemeWrapper from './config/wrappers/ThemeWrapper';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from './bodies/Home.js';
import Navbar from './navbar/Navbar';
import Clients from './bodies/Clients';
import CreateSession from './bodies/CreateSession';

function App() {
  return (
    <ThemeWrapper>
      <BrowserRouter>
        <nav>
          <Navbar />
        </nav>
        <main>
          <Routes>
            <Route path="/" element={<Home />}></Route>
            <Route path="/clients" element={<Clients />}></Route>
            <Route path="/createsession" element={<CreateSession />}></Route>
          </Routes>
        </main>
      </BrowserRouter>
    </ThemeWrapper>
  );
}


export default App;
