import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#ccd5ae',
        },
        secondary: {
            main: '#b7aed5',
        },
        info: {
            main: '#d5cbae',
        },
    }
})

function ThemeWrapper(props) {
    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    )
}

export default ThemeWrapper